import React from 'react'
import Image from '@components/Image'
import {getWebsiteFeaturedCategories_cached_siteCategories_items} from '@data/queries/components/__generated__/getWebsiteFeaturedCategories_cached'
import getCategoryUrl from '@helpers/misc/getCategoryUrl'
import useIsStore from '@hooks/useIsStore'
import useResizedImageUrlSsr from '@hooks/useResizedImageUrlSsr.main'
import Link from 'next/link'

import styles from './styles.module.css'

export default function Category(props: {
  category: getWebsiteFeaturedCategories_cached_siteCategories_items
  backgroundImage: {_id: string}
  showTitleOutside: string
}) {
  const {category, backgroundImage, showTitleOutside} = props
  const isCpg = useIsStore()

  const url = useResizedImageUrlSsr({fileId: backgroundImage._id, width: 700, height: 500})

  return (
    <Link href={getCategoryUrl(category, isCpg)} className={styles.category}>
      {url ? (
        <Image suppressHydrationWarning priority src={url} alt={category.name} layout="fill" objectFit="cover" />
      ) : null}
      {showTitleOutside ? null : <div className={styles.name}>{category.name}</div>}
    </Link>
  )
}
