import getClient from '@providers/apollo/getClient'
import gql from 'graphql-tag'

import {
  getWebsiteFeaturedCategories_cached,
  getWebsiteFeaturedCategories_cachedVariables
} from './__generated__/getWebsiteFeaturedCategories_cached'

export const categoriesQuery = gql`
  query getWebsiteFeaturedCategories_cached($websiteId: ID) {
    siteCategories: categories(websiteId: $websiteId) {
      items {
        _id
        name
      }
    }
  }
`

export default async function getWebsiteCategories(websiteId: string) {
  const {data} = await getClient().query<
    getWebsiteFeaturedCategories_cached,
    getWebsiteFeaturedCategories_cachedVariables
  >({
    query: categoriesQuery,
    variables: {
      websiteId
    }
  })

  return data.siteCategories
}
