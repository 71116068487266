import React from 'react'
import {useSuspenseQuery} from '@apollo/client'
import Loading from '@components/SuspenseLoading/Loading'
import {getWebsiteFeaturedCategories_cached,getWebsiteFeaturedCategories_cachedVariables} from '@data/queries/components/__generated__/getWebsiteFeaturedCategories_cached'
import {categoriesQuery} from '@data/queries/components/categories'
import useWebsiteId from '@hooks/useWebsiteId'
import useWidth from '@hooks/useWidth'

import Category from './Category'

import styles from './styles.module.css'

const MAX_RESOLUTION = 760
const DEFAULT_COLS_VALUES = 3

export interface FeaturedCategoriesProps {
  categories: {categoryId: string; backgroundImage: {_id: string}}[]
  title: React.ReactNode
  siteCategories: any
  showTitleOutside?: string
  cols?: number
  colsMobile?: number
}

function renderCategories({
  showTitleOutside = '',
  categories = [],
  siteCategories = {},
  cols,
}: {
  showTitleOutside?: string
  categories: {categoryId: string; backgroundImage: {_id: string}}[]
  siteCategories: {items?: any[]}
  cols: number
}) {
  if (!categories && !categories.length) return
  return categories.map(({categoryId, backgroundImage}) => {
    const category = siteCategories.items.find(sc => sc._id === categoryId)
    if (!category) return
    return (
      <div
        style={{flex: `1 0 ${Math.floor(100 / cols)}%`}}
        key={categoryId}
        className={styles.categoryContainer}>
        {showTitleOutside ? <div className={styles.name}>{category.name}</div> : null}
        <Category
          showTitleOutside={showTitleOutside}
          category={category}
          backgroundImage={backgroundImage}
        />
      </div>
    )
  })
}

export default function FeaturedCategories(props: FeaturedCategoriesProps) {
  const websiteId = useWebsiteId()
  const screenWidth = useWidth()
  const {cols = DEFAULT_COLS_VALUES} =
    screenWidth <= MAX_RESOLUTION ? {cols: props.colsMobile} : props

  const {data} = useSuspenseQuery<getWebsiteFeaturedCategories_cached, getWebsiteFeaturedCategories_cachedVariables>(categoriesQuery, {
    variables: {websiteId},
    fetchPolicy: 'cache-first'
  })

  const siteCategories = data.siteCategories

  if (!siteCategories) {
    return <Loading />
  }

  return (
    <div className={styles.container}>
      <div className={styles.title}>{props.title}</div>
      {renderCategories({...props, siteCategories, cols})}
    </div>
  )
}
