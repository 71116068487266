import isServerSide from '@helpers/misc/isServerSide'

let result = null

// https://stackoverflow.com/questions/5573096/detecting-webp-support
export default function HasWebpSupport() {
  if (result !== null) {
    return result
  }

  if (isServerSide()) return true

  result = false

  const elem = document.createElement('canvas')

  if (elem.getContext && elem.getContext('2d')) {
    // was able or not to get WebP representation
    try {
      result = elem.toDataURL('image/webp').indexOf('data:image/webp') == 0
    } catch (e) {
      result = false
    }
  }
  // very old browser like IE 8, canvas not supported
  return result
}
