import {WebsiteInitialData_CachedQuery} from '@data/__generated__/types.main'
import isStore from '@helpers/websites/isStore'

import useWebsiteBusinessType from './useWebsiteBusinessType'

export default function useIsStore(website?: WebsiteInitialData_CachedQuery['website']) {
  let businessType = useWebsiteBusinessType()

  if (website?.businessType) {
    businessType = website.businessType
  }

  return isStore(businessType)
}
