import {useSuspenseQuery} from '@apollo/client'
import {
  GetResizedImageSsr_CachedQuery,
  GetResizedImageSsr_CachedQueryVariables
} from '@data/__generated__/types.main'
import hasWebpSupport from '@helpers/misc/hasWebpSupport'
import isServerSide from '@helpers/misc/isServerSide'
import gql from 'graphql-tag'
import clone from 'lodash/clone'

import {localStorageGetItem} from './useLocalStorageState'

export const getResizedImageQuery = gql`
  query getResizedImageSsr_cached(
    $fileId: ID
    $scale: Float
    $width: Float
    $height: Float
    $resizeMode: ID
    $background: String
    $format: String
  ) {
    image(fileId: $fileId) {
      _id
      resizedURL(
        scale: $scale
        width: $width
        height: $height
        resizeMode: $resizeMode
        background: $background
        format: $format
      )
    }
  }
`
export default function useResizedImageUrlSsr(opt) {
  const options = clone(opt)

  if (options.format !== 'jpeg' && hasWebpSupport()) {
    options.format = 'webp'
  }

  const cacheKey = `resizedImage.${JSON.stringify(options)}`
  const cachedResult = isServerSide() ? null : localStorageGetItem(cacheKey)

  const {data} = useSuspenseQuery<
    GetResizedImageSsr_CachedQuery,
    GetResizedImageSsr_CachedQueryVariables
  >(getResizedImageQuery, {
    variables: options,
    fetchPolicy: 'cache-first',
    skip: !!cachedResult || !options.fileId
  })

  if (!options.fileId) return null

  if (cachedResult) return cachedResult

  const image = data.image
  if (!image) return null

  if (!isServerSide()) {
    localStorage?.setItem(cacheKey, image.resizedURL)
  }

  return image.resizedURL
}
